import {
  useReducer,
  useEffect,
  ReducerWithoutAction,
  Dispatch,
  useState
} from 'react'

import { isPresent } from '@utils/logic'
import Redirect from '@utils/redirect'

import { useApollo, initializeApollo } from '@lib/apollo'
import { useGlobalContext } from '@config/contexts'
import { updateTokens, clearTokens, getAuthTokens } from '@config/jwt'
import { removeCookie } from '@utils/cookies'
import { FetchPolicy } from 'apollo-client'

import { AuthContext } from './context'
import { reducer } from './reducer'
import { setAuth } from './actions'
import { User } from '../types/User'

import { usePublisherContext } from '@concepts/Publisher/store/context'

import { useReCaptchaContext } from '@concepts/ReCaptcha/store/contextV3'
import { RecaptchaAction } from '@concepts/ReCaptcha/types/actions'

import AuthData from '../repository/AuthData'
import PromoteGuestUser from '../repository/PromoteGuestUser'
import PasswordRecoveryInstructions from '../repository/PasswordRecoveryInstructions'
import ResetPasswordRepository from '../repository/ResetPassword'
import type { PromoteGuestResult } from '../repository/PromoteGuestUser'
import FastCheckoutSignIn from '../repository/FastCheckoutSignIn'
import FastCheckoutAuth from '../repository/FastCheckoutAuth'
import UserRepository from '../repository/UserRepository'

import checkoutAnalytics from '@lib/gtm/checkoutAnalytics'
import segmentAnalytics from '@lib/segment/analytics'

import { UserFormDTO, UserTokens } from '../domain/User'

import {
  FastCheckoutSignInPayload,
  FastCheckoutAuthPayload
} from 'src/generated/graphql'
import useFavoritedSale from '../hooks/useFavoritedSale'
import useRestAPI from '@lib/http'

type Props = {
  initialUser?: User
  shouldFetchUser?: boolean
}

type PromoteGuestParams = {
  guestToken: string
  email: string
  password: string
}

type UserStatusResponseType = {
  publisherName: string
  status: string
  signUpDate: string
  registeredEmail: string
}

const REFERRAL_COOKIE_NAME = 'rid'
const initialUserEmailRegisteredState = {
  registeredEmail: '',
  userEmailRegistered: false,
  publisherName: '',
  signUpDate: ''
}
const Provider: React.FC<React.PropsWithChildren<Props>> = ({
  initialUser,
  shouldFetchUser,
  children
}) => {
  const { cookies } = useGlobalContext()
  const { favoriteSaleId, removeFavoriteSaleCookie } = useFavoritedSale()
  const { rid } = cookies
  const { databaseId: publisherId, hostname } = usePublisherContext()
  const { currentToken } = useReCaptchaContext()

  const apolloClient = useApollo()
  const APIClient = useRestAPI()

  const [userEmailRegistered, setUserEmailRegistered] = useState(
    initialUserEmailRegisteredState
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, dispatch] = useReducer(reducer as ReducerWithoutAction<any>, {
    user: initialUser,
    isSignedIn: isPresent(initialUser),
    isGuest: Boolean(initialUser?.isGuest),
    loading: true
  })

  const persistAuthAndFetchUserInfo = async (
    tokens: Partial<UserTokens>,
    signUpSource?: string,
    fetchPolicy: FetchPolicy = 'network-only'
  ): Promise<User> => {
    updateTokens(tokens)

    const user = await AuthData.find(
      initializeApollo(
        {},
        {
          auth: tokens,
          hostname: hostname as string
        }
      ),
      fetchPolicy
    )

    setIsLoading(false)

    if (user?.flagged) {
      Redirect({ pathname: '/status/error', external: true })

      return null
    }

    ;(dispatch as Dispatch<unknown>)(setAuth(user))
    segmentAnalytics.identifyWithTraits({
      user,
      signUpSource,
      customParams: {
        publisher_id: publisherId as number
      }
    })

    return user
  }

  const signIn = async (userForm: UserFormDTO): Promise<User> => {
    setIsLoading(true)

    return UserRepository.signIn(
      {
        ...userForm,
        ...favoriteSaleId
      },
      APIClient
    )
      .then((tokens) => {
        removeFavoriteSaleCookie()
        return persistAuthAndFetchUserInfo(tokens)
      })
      .catch((error) => {
        setIsLoading(false)
        return Promise.reject(error)
      })
  }

  const signUp = async (
    userForm: UserFormDTO,
    createAccount: boolean
  ): Promise<User> => {
    const userStatusResponse = await getEmailStatus(userForm.email)

    if (userStatusResponse.status === 'user_exists') {
      setUserEmailRegistered({
        registeredEmail: userStatusResponse.registeredEmail,
        userEmailRegistered: true,
        publisherName: userStatusResponse.publisherName,
        signUpDate: userStatusResponse.signUpDate
      })

      return null
    }

    clearUserEmailRegisteredState()

    if (!createAccount) {
      const user = { email: userForm.email, isGuest: true } as User

      ;(dispatch as Dispatch<unknown>)(setAuth(user))

      return user
    }

    const tokens = await UserRepository.signUp(
      {
        ...userForm,
        ...favoriteSaleId,
        rid,
        sign_up_publisher_id: publisherId as number,
        from_checkout: true
      },
      APIClient
    )

    removeCookie(REFERRAL_COOKIE_NAME)
    removeFavoriteSaleCookie()

    return await persistAuthAndFetchUserInfo(tokens, userForm.source)
  }

  const promoteGuest = async (
    params: PromoteGuestParams
  ): Promise<PromoteGuestResult> => {
    const promotedGuest = await PromoteGuestUser.promote(params, apolloClient)

    if (promotedGuest.refreshToken && promotedGuest.accessToken) {
      await persistAuthAndFetchUserInfo({
        accessToken: promotedGuest.accessToken,
        refreshToken: promotedGuest.refreshToken,
        sessionToken: promotedGuest.sessionToken
      })
    }

    return promotedGuest
  }

  const getEmailStatus = async (
    email: string
  ): Promise<UserStatusResponseType> => {
    const res = await APIClient.get(`api/users/status?email=${email}`)

    const { status, registered_email, sign_up_date, publisher_name } = res.data

    return {
      status,
      registeredEmail: registered_email,
      signUpDate: sign_up_date,
      publisherName: publisher_name
    }
  }

  const forgetUser = (): void => {
    clearTokens()
    ;(dispatch as Dispatch<unknown>)(setAuth(null))
  }

  const signOut = async (): Promise<void> => {
    if (!state.isGuest) await APIClient.delete('/users/auth/')
    forgetUser()
    Redirect({
      pathname: '/',
      external: true
    })
  }

  const clearUserEmailRegisteredState = (): void => {
    setUserEmailRegistered(initialUserEmailRegisteredState)
  }

  const fastCheckoutSignIn = async (
    email: string
  ): Promise<FastCheckoutSignInPayload> =>
    await FastCheckoutSignIn(email, publisherId as number, apolloClient)

  const fastCheckoutAuth = async (
    email: string,
    code: string
  ): Promise<FastCheckoutAuthPayload | void> => {
    const auth = await FastCheckoutAuth(email, code, apolloClient)

    if (auth.refreshToken && auth.accessToken) {
      await persistAuthAndFetchUserInfo({
        accessToken: auth.accessToken,
        refreshToken: auth.refreshToken,
        sessionToken: auth.sessionToken || ''
      })

      checkoutAnalytics.fastCheckoutLogin()
    }

    return auth
  }

  const sendPasswordRecoveryInstructions = async (email: string) =>
    await PasswordRecoveryInstructions.send(email, apolloClient)

  const resetPassword = async (password: string, token: string) => {
    const recaptcha = await currentToken(
      RecaptchaAction.RECAPTCHA_RESET_PASSWORD
    )

    const { success, tokenPair, sessionToken, errors } =
      await ResetPasswordRepository.resetPassword(
        password,
        token,
        {
          token: recaptcha['g-recaptcha-response'],
          resolver: recaptcha.resolver,
          forAction: recaptcha.for_action
        },
        apolloClient
      )

    if (!success) {
      throw new Error(errors[0].message)
    }

    await persistAuthAndFetchUserInfo({ ...tokenPair, sessionToken })
  }

  useEffect(() => {
    if (shouldFetchUser) {
      const tokens = getAuthTokens(cookies, false)

      persistAuthAndFetchUserInfo(
        {
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          sessionToken: tokens.sessionToken
        },
        '',
        'cache-first'
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!shouldFetchUser) {
      ;(dispatch as Dispatch<unknown>)(setAuth(state.user))
    }
  }, [shouldFetchUser, state.user])

  return (
    <AuthContext.Provider
      value={[
        {
          ...userEmailRegistered,
          ...state,
          loading: state.loading || isLoading
        },
        {
          signUp,
          signIn,
          signOut,
          promoteGuest,
          getEmailStatus,
          forgetUser,
          clearUserEmailRegisteredState,
          fastCheckoutSignIn,
          fastCheckoutAuth,
          sendPasswordRecoveryInstructions,
          resetPassword,
          persistAuthAndFetchUserInfo
        }
      ]}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default Provider
