import { pick, pathOr } from 'ramda'

import { Cart } from '@concepts/Cart/types/cart'
import { toMoney } from '@utils/numbers'
import {
  Favorite,
  Publisher,
  Address,
  Sale as GraphQLSale,
  CartItemSource
} from 'src/generated/graphql'
import { Sale } from '@concepts/Cart/types/sale'
import { Item } from '@concepts/Cart/types/item'
import { User } from '@concepts/Auth/types/User'
import { toGender } from '@utils/strings'
import impactRedirectClickId from '@lib/impactRedirect/clickId'
import { Order } from '@concepts/OrderConfirmation/types/order'

import { Maybe } from 'src/types/maybe'

type CheckoutStarted = {
  publisher?: Partial<Publisher>
  cart: Cart
}

type ProductAdded = {
  cartId: number
  hostname: string
  cartItem: Item
  publisherId: number
}

type ProductAddedToWishlist = {
  favorite: Pick<Favorite, 'databaseId'>
  sale: Sale
  publisher: Pick<Publisher, 'databaseId' | 'hostname'>
}

type ProductsSearched = {
  query: string
  user: User
  publisher: Pick<Publisher, 'databaseId'>
}

type HomepageModule = {
  moduleName: string
  productName: string
  currentUrl: string
  destinationUrl: string
  publisherId: number
}

type ClickOnProductRecommendation = {
  productName: string
  currentUrl: string
  publisherId: number
}

type TrackUserSignIn = {
  email: string
  userId: string
  signType: 'email' | 'google' | 'facebook'
  publisherId: number
}

type TrackUserSignUp = TrackUserSignIn

type CouponEvent = {
  cartId: number
  couponCode: string
  reason?: string
  publisherId: number
}

type EmailCapture = {
  emailSubscriberId: number
  userId: number
  publisherId: number
}

type WindowPageArgs = {
  publisher_id?: number | null
}

type IdentifyWithTraits = {
  user: User
  signUpSource?: string
  customParams?: {
    publisher_id: number
  }
}

type TrackEmailBanner = {
  publisherId: number
}

type TrackEmailBannerModal = {
  saleSlug: string
  publisherId: number
}

type AnalyticsSegment = {
  track(event: string, payload?: object): void
  identify(value?: string | null, traits?: object, customParams?: object): void
  identifyWithTraits(args: IdentifyWithTraits): void
  page(publisherId: number): void
  checkoutStarted({ publisher, cart }: CheckoutStarted): void
  orderCompleted(order: Order, cart: Cart): void
  productAdded({ cartId, hostname, cartItem, publisherId }: ProductAdded): void
  productAddedToWishlist({
    favorite,
    sale,
    publisher
  }: ProductAddedToWishlist): void
  completeRegistration(source: string, publisherId: number): void
  productsSearched({ query, user, publisher }: ProductsSearched): void
  homepageModule({
    moduleName,
    productName,
    currentUrl,
    destinationUrl,
    publisherId
  }: HomepageModule): void
  trackEmailBanner(args: TrackEmailBanner): void
  trackEmailBannerModal(args: TrackEmailBannerModal): void
  clickOnProductRecommendation({
    productName,
    currentUrl,
    publisherId
  }: ClickOnProductRecommendation): void
  trackCarousels({
    pageSection,
    itemPosition,
    pageSectionPosition,
    page,
    item,
    publisherId
  }: {
    pageSection: string
    pageSectionPosition?: number
    itemPosition: number
    page?: string
    item: GraphQLSale
    publisherId: number
  }): void
  trackSignIn({ email, userId, signType }: TrackUserSignIn): void
  trackSignUp({ email, userId, signType }: TrackUserSignUp): void
  trackHelloBar(
    currentUrl: string,
    destinationUrl: string,
    helloBarText: Maybe<string>,
    publisherId: number
  ): void
  trackHeroBanner(
    currentURL: string,
    destinationURL: string,
    publisherId: number
  ): void
  couponEntered({ cartId, couponCode, publisherId }: CouponEvent): void
  couponApplied({ cartId, couponCode, publisherId }: CouponEvent): void
  couponDenied({ cartId, couponCode, reason, publisherId }: CouponEvent): void
  couponRemoved({ cartId, couponCode, publisherId }: CouponEvent): void
  emailCapture({ emailSubscriberId, userId, publisherId }: EmailCapture): void
}

type CustomAnalyticsSegment = Omit<AnalyticsSegment, 'page'> & {
  page: (args: WindowPageArgs) => void
}

declare global {
  interface Window {
    analytics: CustomAnalyticsSegment
  }
}

const toSaleAttributes = (
  item: Sale,
  quantity: number,
  source: string,
  hostname: string
) => ({
  product_id: item.id,
  category: item.category?.name,
  name: item.name,
  price: toMoney(item.priceInCents),
  quantity,
  variant: item.saleGroupOption?.name,
  sale_group_option_id: item.saleGroupOption?.databaseId,
  sale_group_id: item.saleGroupOption?.saleGroupId,
  url: `${hostname}/sales/${item.slug}`,
  source
})

const segmentAnalytics: AnalyticsSegment = {
  page: (publisherId) => {
    window.analytics?.page({
      publisher_id: publisherId
    })
  },
  track: (event, payload) => {
    window.analytics?.track(event, payload)
  },
  identify: (value, traits?: {}, customParams?: {}) => {
    window.analytics?.identify(value, traits, customParams)
  },
  identifyWithTraits: ({ user, signUpSource, customParams }) => {
    if (!user) return

    const address = pathOr({}, ['0'], user.addresses) as Address
    const userInfo = user?.info
    segmentAnalytics.identify(String(user.id), {
      name: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      birthday: userInfo?.dateOfBirth,
      gender: toGender(userInfo?.gender as string),
      phone: userInfo?.phoneNumber || address?.phoneNumber,
      address: {
        country: userInfo?.country || address?.countryCode,
        city: userInfo?.city || address?.city,
        postalCode: userInfo?.zip || address?.zip,
        state: userInfo?.state || address?.state
      },
      customParams
    })

    if (signUpSource)
      segmentAnalytics.completeRegistration(
        signUpSource,
        customParams?.publisher_id as number
      )
  },
  completeRegistration: (source, publisherId) => {
    window.analytics?.track('CompleteRegistration', {
      content_name: source,
      publisher_id: publisherId
    })
  },
  checkoutStarted: ({ publisher, cart }) => {
    const checkoutStartedEvent = {
      cart_id: cart.id,
      publisher_id: publisher?.databaseId,
      total: toMoney(cart.summary.totalInCents),
      shipping: toMoney(cart.shippingCostCents || cart.intShippingCostCents),
      coupon: null,
      products: cart.items.map((item) =>
        toSaleAttributes(
          item.sale,
          item.quantity,
          item.source,
          String(publisher?.hostname)
        )
      ),
      action_source: 'website'
    }

    window.analytics?.track('Checkout Started', checkoutStartedEvent)
  },
  orderCompleted: (order, cart) => {
    const orderCompletedEvent = {
      order_id: order.databaseId,
      publisher_id: order.publisher.databaseId,
      event_id: order.databaseId,
      total: toMoney(order.priceInCents as number),
      shipping: toMoney(order.shippingCostInCents),
      tax: toMoney(order.taxInCents),
      discount: toMoney(order.discountInCents),
      coupon: order.coupon?.code || null,
      currency: order.currency.code,
      revenue: toMoney(order.subtotalInCents),
      products: order.lineItems.map((item) => ({
        product_id: item.databaseId,
        category: item.category?.name,
        name: item.name,
        price: toMoney(item.unitPriceInCents),
        quantity: item.quantity,
        url: `${order.publisher.hostname}/sales/${item.slug}`,
        source: cart.items.find(
          (cartItem) => cartItem.sale.id === item.databaseId
        )?.source
      })),
      action_source: 'website',
      ...impactRedirectClickId.consumeForEvent()
    }

    window.analytics?.track('Order Completed', orderCompletedEvent)
  },
  productAdded: ({ cartId, hostname, cartItem, publisherId }) => {
    const productAddedEvent = {
      cart_id: cartId,
      action_source: 'website',
      ...toSaleAttributes(
        cartItem.sale,
        cartItem.quantity,
        cartItem.source,
        hostname
      ),
      publisher_id: publisherId
    }

    window.analytics?.track('Product Added', productAddedEvent)
  },
  productAddedToWishlist: ({ favorite, sale, publisher }) => {
    const eventData = {
      action_source: 'website',
      wishlist_id: String(favorite.databaseId),
      publisher_id: String(publisher.databaseId),
      ...pick([
        'product_id',
        'name',
        'price',
        'category',
        'sale_group_id',
        'sale_group_option_id',
        'url',
        'variant'
      ])(
        toSaleAttributes(
          sale,
          1,
          CartItemSource.Wishlist,
          publisher.hostname as string
        )
      )
    }

    window.analytics?.track('Product Added to Wishlist', eventData)
  },
  productsSearched: ({ query, user, publisher }) => {
    const eventData = {
      query_string: query,
      user_id: user?.id,
      publisher_id: publisher?.databaseId,
      action_source: 'website'
    }

    window.analytics?.track('Products Searched', eventData)
  },
  homepageModule: ({
    moduleName,
    productName,
    currentUrl,
    destinationUrl,
    publisherId
  }) => {
    const moduleData = {
      module_name: moduleName,
      product_name: productName,
      current_url: currentUrl,
      destination_url: destinationUrl,
      publisher_id: publisherId
    }

    window.analytics?.track(`Module ${moduleName}`, moduleData)
  },
  trackEmailBanner: ({ publisherId }) => {
    window.analytics?.track('Email Banner Clicked', {
      publisher_id: publisherId
    })
  },
  trackEmailBannerModal: ({ saleSlug, publisherId }) => {
    const eventData = {
      product_url: saleSlug,
      publisher_id: publisherId
    }

    window.analytics?.track('Email Modal Button Clicked', eventData)
  },
  clickOnProductRecommendation: ({ productName, currentUrl, publisherId }) => {
    const eventData = {
      product_name: productName,
      current_url: currentUrl,
      publisher_id: publisherId
    }

    window.analytics?.track('Product Recommendation Clicked', eventData)
  },
  trackCarousels: ({
    pageSection,
    pageSectionPosition,
    itemPosition,
    item,
    page,
    publisherId
  }) => {
    const eventData = {
      page_section: pageSection,
      page_section_position: pageSectionPosition,
      item: {
        position: itemPosition,
        database_id: item.databaseId,
        product_name: item.name
      },
      publisher_id: publisherId
    }

    window.analytics?.track(`module ${pageSection}-${page}`, eventData)
  },
  trackSignIn: ({ email, userId, signType, publisherId }: TrackUserSignIn) => {
    const eventData = {
      email,
      userId,
      signType,
      publisher_id: publisherId
    }

    window.analytics?.track('Signed In', eventData)
  },
  trackSignUp: ({ email, userId, signType, publisherId }: TrackUserSignUp) => {
    const eventData = {
      email,
      userId,
      signType,
      publisher_id: publisherId
    }

    window.analytics?.track('Signed Up', eventData)
  },
  trackHelloBar: (currentUrl, destinationUrl, helloBarText, publisherId) => {
    const eventData = {
      event_label: 'link',
      current_url: currentUrl,
      destination_url: destinationUrl,
      hellobar_text: helloBarText,
      publisher_id: publisherId
    }

    window.analytics?.track('Hello Bar Clicked', eventData)
  },

  trackHeroBanner: (currentUrl, destinationUrl, publisherId) => {
    const eventData = {
      event_label: 'link',
      current_url: currentUrl,
      destination_url: destinationUrl,
      publisher_id: publisherId
    }

    window.analytics?.track('Hero Banner Clicked', eventData)
  },
  couponEntered: ({ cartId, couponCode, publisherId }) => {
    const eventData = {
      cart_id: cartId,
      coupon_code: couponCode,
      publisher_id: publisherId
    }

    window.analytics?.track('Coupon Entered', eventData)
  },
  couponApplied: ({ cartId, couponCode, publisherId }) => {
    const eventData = {
      cart_id: cartId,
      coupon_code: couponCode,
      publisher_id: publisherId
    }

    window.analytics?.track('Coupon Applied', eventData)
  },
  couponDenied: ({ cartId, couponCode, reason, publisherId }) => {
    const eventData = {
      cart_id: cartId,
      coupon_code: couponCode,
      reason,
      publisher_id: publisherId
    }

    window.analytics?.track('Coupon Denied', eventData)
  },
  couponRemoved: ({ cartId, couponCode, publisherId }) => {
    const eventData = {
      cart_id: cartId,
      coupon_code: couponCode,
      publisher_id: publisherId
    }

    window.analytics?.track('Coupon Removed', eventData)
  },
  emailCapture: ({ emailSubscriberId, userId, publisherId }) => {
    const eventData = {
      email_subscriber_id: emailSubscriberId,
      user_id: userId,
      publisher_id: publisherId
    }
    window.analytics?.track('Email Captured', eventData)
  }
}

export default segmentAnalytics
